import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwiperCore from 'swiper';
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Mousewheel,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';
import Image from 'next/image';

import styles from './Carousel.module.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);

const Carousel = ({ title = '', cards = [] }) => {
    const isSingleCard = cards.length === 1;

    const sliderSettings = {
        modules: { Scrollbar, A11y },
        spaceBetween: 8,
        slidesPerView: 1.2,
        breakpoints: {
            532: {
                spaceBetween: 16,
                slidesPerView: 1.8,
            },
            1024: {
                spaceBetween: 24,
                slidesPerView: 3,
                noSwipingSelector: 'a',
            },
        },
        scrollbar: { draggable: true },
        mousewheel: {
            forceToAxis: true,
            releaseOnEdges: true,
        },
    };

    return (
        <section className={styles['Carousel']}>
            <div className={styles['Carousel__Container']}>
                {!!title && (
                    <h2 className={styles['Carousel__Title']}>{title}</h2>
                )}
                {isSingleCard ? (
                    <div className={styles['Carousel__Card']}>
                        {cards.map((card, index) => (
                            <Card key={index} {...card} />
                        ))}
                    </div>
                ) : (
                    <Swiper
                        className={styles['Carousel__Slider']}
                        {...sliderSettings}
                    >
                        {cards.map((card, index) => (
                            <SwiperSlide key={index}>
                                <Card {...card} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </div>
        </section>
    );
};

Carousel.propTypes = {
    title: PropTypes.string,
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string,
            image: PropTypes.shape({
                renditions: PropTypes.shape({
                    mobile: PropTypes.shape({
                        src: PropTypes.string,
                        width: PropTypes.number,
                        height: PropTypes.number,
                    }),
                    mobile2x: PropTypes.shape({
                        src: PropTypes.string,
                        width: PropTypes.number,
                        height: PropTypes.number,
                    }),
                    desktop: PropTypes.shape({
                        src: PropTypes.string,
                        width: PropTypes.number,
                        height: PropTypes.number,
                    }),
                    desktop2x: PropTypes.shape({
                        src: PropTypes.string,
                        width: PropTypes.number,
                        height: PropTypes.number,
                    }),
                }),
            }),
            label: PropTypes.string,
            title: PropTypes.string,
        })
    ),
};

const Card = ({ href, image, label, title }) => {
    const [isLoaded, setLoaded] = useState(false);
    const imageClasses = classNames(
        styles['Card__Image'],
        styles['Card__FadeIn'],
        {
            [styles['Card__FadeIn--Loaded']]: isLoaded,
        }
    );

    return (
        <article className={styles['Card']}>
            <a className={styles['Card__Link']} href={href}>
                <span className="sr-only">{title}</span>
            </a>
            <div className={styles['Card__Inner']}>
                <LazyLoad once>
                    <div className={styles['Card__ImageContainer']}>
                        {image && image.url && (
                            <Image
                                className={imageClasses}
                                loading="lazy"
                                src={image.url}
                                fill
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: `${image.focal.x} ${image.focal.y}`,
                                }}
                                sizes={'(min-width: 1680px) 640px'}
                                onLoad={() => setLoaded(true)}
                                alt={image.altText}
                            />
                        )}
                    </div>
                </LazyLoad>
                <div className={styles['Card__Content']}>
                    <span className={styles['Card__Label']}>{label}</span>
                    <h3 className={styles['Card__Title']} aria-hidden>
                        {title}
                    </h3>
                </div>
            </div>
        </article>
    );
};

export default Carousel;
